<template>
    <div class="modal" id="reviewModal" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title review_title">Tell everyone how was it to work with {{ add_review.editor_name }}</h5>
                </div>
                <div class="modal-body">
                    <div class="box_body">
                        <star-rating
                            v-model="add_review.rating"
                            :show-rating="false"
                            v-bind:star-size="22"
                            inactive-color="#D1D2D3"
                            :active-color="getDefaultColor"/>
                        <textarea rows="4" v-model="add_review.message" placeholder="Leave a review..."/>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn_modal_t1" data-bs-dismiss="modal">Cancel</button>
                    <button class="btn_modal_t2" @click="postReview">Post</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";
import helpFunctions from "../../helpFunctions";
import {errorMessage} from "../../services/messages";
import StarRating from 'vue-star-rating';
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "ReviewModal",
    components: {
        StarRating,
    },
    data() {
        return {
            reviewModal: null,
            add_review: {},
        }
    },
    async mounted() {
        this.add_review = helpFunctions.add_review;
        this.reviewModal = new Modal(document.getElementById('reviewModal'));
    },
    destroyed() {
        if (this.reviewModal) {
            this.reviewModal = null;
        }
    },
    computed: {
        ...mapGetters([
            'getDefaultColor'
        ]),
    },
    methods: {
        ...mapMutations([
            'showLoader',
            'hideLoader',
        ]),
        load(job) {
            this.add_review.editor_id = job?.user_work?.user_id || null;
            this.add_review.image_job_id = job.id;
            this.add_review.editor_name = job?.user_work?.user?.first_name || '';
            this.reviewModal.show();
        },
        async postReview() {
            if (!this.add_review.rating) {
                errorMessage('Rating is required');
                return;
            }
            try {
                this.showLoader();
                await this.$http.postAuth(`${this.$http.apiUrl()}create-review`, {
                    'to_user_id': this.add_review.editor_id,
                    'job_image_id': this.add_review.image_job_id,
                    'message': this.add_review.message,
                    'rating': this.add_review.rating,
                });
            } catch (e) {
                const message = e?.response?.data?.error?.message || 'ERROR';
                errorMessage(message);
            }
            this.hideLoader();
            this.clearAddReview();
            this.$emit('getProfileUser');
            this.reviewModal.hide();
        },
        clearAddReview() {
            this.add_review.rating = 0;
            this.add_review.editor_id = null;
            this.add_review.image_job_id = null;
            this.add_review.message = '';
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-title {
    width: 100%;
    text-align: center;
}
.review_title {
    padding: 25px 0;
}
.box_body {
    background: #f4f5f5;
    border-radius: 3px;
    padding: 16px 30px;
}
.box_body textarea {
    border: 0px;
    resize: vertical;
    width: 100%;
    background: #f4f5f5;
    margin-top: 15px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(73, 73, 73, 0.65);
}
.modal-footer {
    border-top: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    gap: 35px;
}
.btn_modal_t1 {
    border: 0.5px solid #494949;
    box-sizing: border-box;
    border-radius: 100px;
    background: white;
    height: 47px;
    width: 148px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #494949;
}
@media (max-width: 992px) {
    .modal-footer {
        border-top: none;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        gap: 15px;
    }
}
</style>
